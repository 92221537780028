import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from 'src/app/services/dashboard.service';
import { PageEvent } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelService } from 'src/app/services/excel.service';
@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent implements OnInit {
  onBoardlisting: any;
  dealerresponse: any;
  guruonborading: any;
  updateexcel: any;
  showNodata: boolean = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  pgIndex: number = 0;
  constructor( private dashboardservice:DashboardService,private spinner: NgxSpinnerService,private excelService:ExcelService) { }

  displayedColumns = ["requestId", "date", "status", "download"];
  ngOnInit(): void {
    this.dealerlist(0, this.pageSize);
    
  }
  activeTab: string = 'dealer'; // Default tab

  setActiveTab(tab: string): void {
    this.activeTab = tab;
    this.currentPage = 0; // Reset to first page
    this.pageSize = 10;  
    if(tab=='guruonboarding'){
      this.onBoardList(0, this.pageSize);
    }
    else if(tab=='gurupdation'){
      this.uploadExcelList(0, this.pageSize);
    }
    else if(tab=='dealer'){
      this.dealerlist(0, this.pageSize);

    }
  }
  refreshBtn(){
    if(this.activeTab=='guruonboarding'){
      this.onBoardList(0, this.pageSize);
    }
    else if(this.activeTab=='gurupdation'){
      this.uploadExcelList(0, this.pageSize);
    }
    else if(this.activeTab=='dealer'){
      this.dealerlist(0, this.pageSize);

    }
  }
  data: any[] = [];
  // onFileChange(event: any): void {
  //   const file = event.target.files[0];
  
  //   if (file) {
  //     const fileReader = new FileReader();
  //     fileReader.onload = (e: any) => {
  //       const binaryData = e.target.result;
  //       const workbook = XLSX.read(binaryData, { type: 'binary' });
  
  //       if (workbook.SheetNames.length === 0) {
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Error',
  //           text: 'The uploaded Excel file is empty!',
  //         });
  //         return;
  //       }
  
  //       const sheetName = workbook.SheetNames[0]; // Get the first sheet
  //       const sheetData = workbook.Sheets[sheetName];
  //       const jsonData = XLSX.utils.sheet_to_json(sheetData); // Convert to JSON
  
  //       if (jsonData.length === 0) {
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Error',
  //           text: 'The uploaded Excel file has no data!',
  //         }).then((result) => {
  //           if (result.isConfirmed) {
  //               this.resetFileInput(this.activeTab); // Pass the active tab value
  //           }
  //       });
  //         return;
  //       }
  
  //       this.data = jsonData;
  //     };
  //     fileReader.readAsBinaryString(file); // Read the file as binary
  //   }
  // }

  onFileChange(event: any): void {
    const file = event.target.files[0];

    if (file) {
        const fileReader = new FileReader();
        fileReader.onload = (e: any) => {
            const binaryData = e.target.result;
            const workbook = XLSX.read(binaryData, { type: 'binary' });

            if (workbook.SheetNames.length === 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'The uploaded Excel file is empty!',
                });
                return;
            }

            const sheetName = workbook.SheetNames[0]; // Get the first sheet
            const sheetData = workbook.Sheets[sheetName];

            // Convert to JSON while ensuring all values are strings
            const jsonData = XLSX.utils.sheet_to_json(sheetData, { defval: "", raw: false });

            if (jsonData.length === 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'The uploaded Excel file has no data!',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.resetFileInput(this.activeTab);
                    }
                });
                return;
            }

            // Convert all data values to strings
            const formattedData = jsonData.map((row: any) => {
                let cleanedRow: any = {};
                Object.keys(row).forEach((key) => {
                    cleanedRow[key] = row[key] !== null && row[key] !== undefined ? String(row[key]).trim() : "";
                });
                return cleanedRow;
            });

            this.data = formattedData;
        };
        fileReader.readAsBinaryString(file);
    }
}



  uploadexcel() {
    if (!this.data.length) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please select a file to upload!',
      }).then((result) => {
        if (result.isConfirmed) {
            this.resetFileInput(this.activeTab); // Pass the active tab value
        }
    });
      return;
    }
    if (this.data.length > 50) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'File contains more than 50 records. Please upload a file with a maximum of 50 records.',
      }).then((result) => {
        if (result.isConfirmed) {
            this.resetFileInput(this.activeTab); // Pass the active tab value
        }
    });
      return;
   }
   
    this.spinner.show();
  
    // Define required headers for each tab
    const requiredHeadersMap: { [key: string]: { required: string[], optional?: string[] } } = {
      dealer: {
        required: ['username', 'first_name', 'last_name', 'mobile_no', 'state', 'region' ],
        optional: ['email_id']
      },
      guruonboarding: {
        required: [
          'account_name', 'main_phone_number', 'account_type','pincode',
          'sales_division_name', 'service_division_name', 'address_line1',
          'latitude', 'longitude', 'dealer_code', 'service_dealer_login_s', 'zone',
          'contact_first_name', 'contact_last_name','lass_contact'
        ],
        optional: ['address_line2', 'email']
      },
      gurupdation: {
        required: [
          'account_type', 'account_id', 'sales_division_name', 'service_division_name'
        ],
        optional: ['service_dealer_login_s', 'lass_contact']
      }
    };
  
    // Validate headers for the active tab
    if (requiredHeadersMap[this.activeTab]) {
      const { required, optional = [] } = requiredHeadersMap[this.activeTab];
  
      // If headers are incorrect, STOP execution
      if (!this.validateHeaders(this.data, required, optional)) {
        this.spinner.hide();
        return;  // 🚀 Stops execution, prevents extra error messages
      }
    }
  
    // Select API call based on active tab
    let apiCall;
    let successCallback: Function;
  
    switch (this.activeTab) {
      case 'dealer':
        apiCall = this.dashboardservice.dealerUpload(this.data);
        successCallback = () => this.dealerlist(0, this.pageSize);
        break;
      case 'guruonboarding':
        apiCall = this.dashboardservice.excelCreate(this.data);
        successCallback = () => this.onBoardList(0, this.pageSize);
        break;
      case 'gurupdation':
        apiCall = this.dashboardservice.excelUpdate(this.data);
        successCallback = () => this.uploadExcelList(0, this.pageSize);
        break;
      default:
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Invalid upload type.',
        });
        return;
    }
  
    // Execute API call
    apiCall.subscribe(
      (res: any) => {
        console.log('API Response:', res); // Console log the response
    
        this.spinner.hide();
    
        if (res.success) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'File uploaded successfully',
          });
          successCallback();
        } else {
          // Extract validation_result messages from the response data
          // let validationMessages = res.data.map((item: any) => item.validation_result).join('\n');
    
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text:  'Something went wrong',
          });
        }
    
        this.resetFileInput(this.activeTab);
      },
      (error) => {
        console.error('API Error:', error); // Console log the error response
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred during file upload.',
        });
      }
    );
    
  }
  
  
  
  
  validateHeaders(data: any[], requiredHeaders: string[], optionalHeaders: string[] = []): boolean {
    if (!data.length) return false;

    // Normalize headers: Trim, lowercase, and remove special hidden characters
    const normalize = (str: string) => str.trim().toLowerCase().replace(/\u200B/g, ""); 

    const fileHeaders = Object.keys(data[0]).map(normalize);
    const required = requiredHeaders.map(normalize);

    // Check for missing required headers
    const missingHeaders = required.filter(header => !fileHeaders.includes(header));

    if (missingHeaders.length) {
        console.error("Missing Required Headers:", missingHeaders);

        Swal.fire({
            icon: 'error',
            title: 'Missing Required Headers',
            html: `<b>The following required fields are missing:</b><br><br> <ul style="text-align:left;"> 
                  ${missingHeaders.map(header => `<li>${header}</li>`).join("")} 
                  </ul>`,
            confirmButtonText: 'OK'
        }).then((result) => {
            if (result.isConfirmed) {
                this.resetFileInput(this.activeTab); // Pass the active tab value
            }
        });

        return false;
    }

    return true;
}


  
  






  resetFileInput(tab: string): void {
    if (tab === 'dealer') {
      this.data = []
      const fileInput = document.getElementById('fileInput') as HTMLInputElement;
      if (fileInput) {
        fileInput.value = ''; // Clear the file input field
      }
    }
    else if (tab === 'guruonboarding') {
      this.data = []
      const fileInput = document.getElementById('onboardInput') as HTMLInputElement;
      if (fileInput) {
        fileInput.value = ''; // Clear the file input field
      }
    }
    else if (tab === 'gurupdation') {
      this.data = []
      const fileInput = document.getElementById('updateInput') as HTMLInputElement;
      if (fileInput) {
        fileInput.value = ''; // Clear the file input field
      }
    }
  }
dataSource = new MatTableDataSource<any>();

onBoardList(page: number = 0, pageSize: number = 10) {
  try {
    this.spinner.show();
    const ListInput: any = {};
    ListInput.offset = page;
    ListInput.limit = pageSize;
    ListInput.action_type = "create";

    this.dashboardservice.guruOnboard(ListInput).subscribe((res: any) => {
      this.spinner.hide();

      if (res && res.data && res.data.length > 0) {
        this.dataSource.data = res.data;
        this.totalRows = res.total_count || 0; // Ensure total count is assigned properly
        this.showNodata = false;
      } else {
        this.dataSource.data = [];
        this.totalRows = 0; 
        this.showNodata = true;
      }

    }, (error: any) => {
      this.spinner.hide();
      console.error('Error fetching onboard list:', error);
    });
  } catch (error) {
    this.spinner.hide();
    console.error('Unexpected error:', error);
  }
}
dealerlist(page: number = 0, pageSize: number = 10) {
  try {
    this.spinner.show();
    const ListInput: any = {};
    ListInput.offset = page;
    ListInput.limit = pageSize;
    ListInput.action_type = "dealer";

    this.dashboardservice.guruOnboard(ListInput).subscribe((res: any) => {
      this.spinner.hide();

      if (res && res.data && res.data.length > 0) {
        this.dataSource.data = res.data;
        this.totalRows = res.total_count || 0; // Ensure total count is assigned properly
        this.showNodata = false;
      } else {
        this.dataSource.data = [];
        this.totalRows = 0; 
        this.showNodata = true;
      }
    }, (error: any) => {
      this.spinner.hide();
      console.error('Error fetching onboard list:', error);
    });
  } catch (error) {
    this.spinner.hide();
    console.error('Unexpected error:', error);
  }
}


uploadExcelList(page: number = 0, pageSize: number = 10) {
  try {
    this.spinner.show();
    const ListInput: any = {};
    ListInput.offset = page;
    ListInput.limit = pageSize;
    ListInput.action_type = "update";

    this.dashboardservice.guruOnboard(ListInput).subscribe((res: any) => {
      this.spinner.hide();

      if (res && res.data && res.data.length > 0) {
        this.dataSource.data = res.data;
        this.totalRows = res.total_count || 0;
        this.showNodata = false;
      } else {
        this.dataSource.data = [];
        this.totalRows = 0;
        this.showNodata = true;
      }
    }, (error: any) => {
      this.spinner.hide();
      console.error('Error fetching Excel list:', error);
    });
  } catch (error) {
    this.spinner.hide();
    console.error('Unexpected error:', error);
  }
}




pageChanged(event: PageEvent) {
  this.currentPage = event.pageIndex;
  this.pageSize = event.pageSize;
  let offset = this.currentPage * this.pageSize;



  if (this.activeTab === 'guruonboarding') {
    this.onBoardList(offset, this.pageSize);
  } else if (this.activeTab === 'gurupdation') {
    this.uploadExcelList(offset, this.pageSize);
  }
  else if(this.activeTab === 'dealer'){
    this.dealerlist(offset, this.pageSize);
}
}






  async downloadReport(value: any) {
    try {
        this.spinner.show();

        let actionType = 'create'; // Default value
        if (this.activeTab === 'gurupdation') {
            actionType = 'update';
        } else if (this.activeTab === 'dealer') {
            actionType = 'dealer';
        }else if(this.activeTab === 'guruonboarding'){
          actionType = 'create';
        }
        let reqObj = {
            "request_id": value.request_id,
            "offset": 0,
            "limit": 10,
            "action_type": actionType
        };

        let totalResult = 0;
        let data: any[] = [];
        let res = await this.dashboardservice.singleDownloadexcel(reqObj);

        if (res) {
            totalResult = res.total_count;
            data = res.result;
        }

        let rou = Math.floor(totalResult / 250);
        rou = (totalResult % 250 > 0) && (rou > 0) ? rou + 1 : rou;

        if (rou > 1) {
            for (let i = 1; i < rou; i++) {
                reqObj.offset = (i * 250);
                res = await this.dashboardservice.singleDownloadexcel(reqObj);
                if (res && res.success && res.result.length) {
                    data = [...data, ...res.result];
                }
            }
        }

        let finalData: ResponseItem[] = [];
        let finalData2: Account[] = [];
        let finalData3: Dealer[] = [];
        for (let row of data) {
            const ListInput: ResponseItem = {
                account_type: row.account_type || "NA",
                sales_division_name: row.sales_division_name || "NA",
                service_division_name: row.service_division_name || "NA",
                service_dealer_login_s: row.service_dealer_login_s || "NA",
                is_fail: row.is_fail !== undefined ? row.is_fail : false,
                remarks: row.remarks || "NA",
                account_id: row.account_id || "NA"
            };

            const ListInput2: Account = {
              id: row.id !== undefined ? row.id : 0,
              account_name: row.account_name || "NA",
              main_phone_number: row.main_phone_number || "NA",
              account_type: row.account_type || "NA",
              account_channel: row.account_channel || "NA",
              email: row.email || "NA",
              location: row.location || "NA",
              account_status: row.account_status || "NA",
              sales_division_name: row.sales_division_name || "NA",
              service_division_name: row.service_division_name || "NA",
              service_dealer_login_s: row.service_dealer_login_s || "NA",
              address_line1: row.address_line1 || "NA",
              address_line2: row.address_line2 || "NA",
              pincode: row.pincode || "NA",
              remarks: row.remarks || "NA",
              reason: row.reason || "NA",
              is_fail: row.is_fail !== undefined ? row.is_fail : false,
              contact_first_name: row.contact_first_name || "NA",
              contact_last_name: row.contact_last_name || "NA",
              request_id: row.request_id || "NA",
              dealer_code: row.dealer_code || "NA",
              latitude: row.latitude !== undefined ? row.latitude : 0,
              longitude: row.longitude !== undefined ? row.longitude : 0,
              zone: row.zone || "NA",
              lass_contact: row.lass_contact || "NA",
              account_id: row.account_id !== undefined ? row.account_id : null,
              action_type: row.action_type || "NA",
              created_date: row.created_date || "NA",
              updated_date: row.updated_date || "NA",
             
            };
            const ListInput3: Dealer = {
              mobile_no: row.mobile_no || "NA",
              username: row.username || "NA",
              first_name: row.first_name || "NA",
              last_name: row.last_name || "NA",
              region: row.region || "NA",
              state_code: row.state_code || "NA",
              email: row.email || "NA",
              remarks:row.remarks||"NA",
              is_fail:row.is_fail||"NA"
          };
          

            finalData.push(ListInput);
            finalData2.push(ListInput2);
            finalData3.push(ListInput3);
        }

        let reportName = "Report";
        if (this.activeTab === 'gurupdation') {
            reportName = "Guru Updation";
            if (finalData.length > 0) {
                this.excelService.exportCancellationAsExcelFile(finalData, reportName);
            }
        } else if (this.activeTab === 'guruonboarding') {
            reportName = "Guru Onboarding";
            if (finalData2.length > 0) {
                this.excelService.exportCancellationAsExcelFile(finalData2, reportName );
            }
        }else if (this.activeTab === 'dealer') {
          reportName = "Dealer Onboarding";
          if (finalData2.length > 0) {
              this.excelService.exportCancellationAsExcelFile(finalData3, reportName );
          }
      }

    } catch (error) {
        console.error("Error downloading report:", error);
    } finally {
        this.spinner.hide();
    }
}


}

export interface ResponseItem {
  account_type: string;
  sales_division_name: string;
  service_division_name: string;
  service_dealer_login_s: string;
  is_fail: boolean;
  remarks: string;
  account_id: string;
}

export interface Account {
  id: number;
  account_name: string;
  main_phone_number: string;
  account_type: string;
  account_channel: string;
  email: string;
  location: string;
  account_status: string;
  sales_division_name: string;
  service_division_name: string;
  service_dealer_login_s:string;
  address_line1: string;
  address_line2: string;
  pincode: string;
  remarks: string;
  reason: string | null;
  is_fail: boolean;
  contact_first_name: string;
  contact_last_name: string;
  request_id: string;
  dealer_code: string;
  latitude: number;
  longitude: number;
  zone: string;
   lass_contact: string;
  account_id: number | null;
  action_type: string;
  created_date: string;
  updated_date: string;
}
export interface Dealer {
  mobile_no: string;
  username: string;
  first_name: string;
  last_name: string;
  region: string;
  email: string;
  remarks:String,
  state_code:string
  is_fail:string
}
